import { GetStaticPaths, GetStaticProps, GetStaticPropsResult } from 'next'
import { When } from 'react-if'
import { paths, slugs } from '@/constants'
import { Durations } from '@/constants/durations'
import { ProjectMetaProvider } from '@/contexts/ProjectMetaContext'
import { TheatricalRegionProvider } from '@/contexts/TheatricalRegionContext/TheatricalRegionContext'
import { Seo } from '@/layout/Seo'
import { MovieJsonLd } from '@/layout/Seo/JsonLd'
import { NextPageWithLayout } from '@/layouts'
import { TheatricalFooter, TheatricalFooterProps } from '@/organisms/TheatricalFooter'
import { getWebClient } from '@/services/ApolloClient'
import { getTheatricalReleaseBySlug } from '@/services/CmsService'
import {
  CatalogMovie,
  fetchTitle,
  formatTitleFriendlyReleaseDate,
  formatTitleReleaseYear,
  mapJsonLdProps,
  mapSeoProps,
} from '@/services/ContentCatalog'
import { getLightFranchiseBySlug, LightFranchise } from '@/services/FranchiseService'
import { getProjectLivestreams, PlayableLivestream } from '@/services/LivestreamService'
import { inTheaters, isSellingTickets, isStreaming } from '@/services/PhaseManagerService'
import { getContentfulProjectTheme } from '@/services/ProjectThemeService'
import { getProjectAbout, ProjectMeta } from '@/services/ProjectsService'
import {
  formatFilmName,
  getCurrentYear,
  getTheatricalPreReleasePageData,
  GetTheatricalPreReleasePageDataResult,
  isProjectThatsBothSeriesAndMovie,
  useMoviesTitle,
} from '@/services/TheatricalPreReleaseService'
import { getWhereToWatch, WhereToWatch } from '@/services/WhereToWatchService'
import { ProjectTheme, TheatricalRegion } from '@/types/codegen-contentful'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { getStaticPropsErrorHandler, getUrlFromThisFilePath } from '@/utils/nextUtils/nextErrorHandlers'
import { omitUndefineds } from '@/utils/object'
import { redact } from '@/utils/sensitive-data'
import { shouldHideFromSearchEngines } from '@/utils/seo/searchEngineHelper'
import { loadTranslations } from '@/utils/translate/translate-server'
import { WatchTheatricalView } from '@/views/WatchTheatricalView'
import { WatchTheatricalContextProvider } from '@/views/WatchTheatricalView/WatchTheatricalContext'

interface MoviesPageProps extends Omit<ProjectMeta, 'primaryFlowPhases'> {
  allTheatricalReleaseRegions: TheatricalRegion[]
  catalogTitle?: CatalogMovie
  inTheaters: boolean
  isStreaming: boolean
  livestreams: PlayableLivestream[] | null
  locale: string
  pageData: GetTheatricalPreReleasePageDataResult
  whereToWatch: WhereToWatch
  contentfulProjectTheme: ProjectTheme
  supportedTheatricalRegions: TheatricalRegion[]
  canShowTickets?: boolean
  franchise?: LightFranchise
  projectMeta: ProjectMeta
}

export const MoviesPage: NextPageWithLayout<MoviesPageProps> = (props) => {
  const { filmDetails } = props.pageData
  const { inTheaters, isStreaming, locale, franchise, projectMeta } = props
  const shouldHide = shouldHideFromSearchEngines(props)

  const path = `${paths.movies.index}/${props.slug}`
  const catalogTitle = props.catalogTitle
  const title = useMoviesTitle({
    filmDetails,
    filmName: catalogTitle ? formatFilmName(catalogTitle) : projectMeta.name,
    inTheaters,
    isStreaming:
      props.slug === slugs.cabrini ||
      props.slug === slugs.sight ||
      props.slug === slugs.theBlind ||
      props.slug === slugs.theWay ||
      props.slug === slugs.redemptionOfHenryMyers ||
      props.slug === slugs.sweetwater ||
      props.slug === slugs.surprisedByOxford ||
      props.slug === slugs.somethingToStandForWithMikeRowe ||
      isStreaming,
    releaseYear: formatTitleReleaseYear(catalogTitle) || getCurrentYear(),
    releaseDate: formatTitleFriendlyReleaseDate(props.locale, catalogTitle),
  })

  return (
    <>
      <Seo
        {...mapSeoProps({ path, title, catalogTitle, locale, projectMeta })}
        noindex={shouldHide}
        nofollow={shouldHide}
        path={path}
      />
      {/* TODO: soon, all pages will have catalog titles, and this can be removed */}
      {catalogTitle && <MovieJsonLd {...mapJsonLdProps({ path, catalogTitle, locale })} />}
      <ProjectMetaProvider project={props}>
        <TheatricalRegionProvider
          supportedTheatricalRegions={props.supportedTheatricalRegions}
          regionData={props.allTheatricalReleaseRegions}
        >
          <WatchTheatricalContextProvider pageData={props.pageData} franchise={franchise}>
            <WatchTheatricalView
              catalogTitle={catalogTitle}
              livestreams={props.livestreams}
              whereToWatch={props.whereToWatch}
              contentfulProjectTheme={props.contentfulProjectTheme}
              canShowTickets={props.canShowTickets}
            />
            <When condition={props.slug === slugs.soundOfFreedom}>
              <TheatricalFooter {...(filmDetails?.filmAssociationLogos as TheatricalFooterProps)} />
            </When>
          </WatchTheatricalContextProvider>
        </TheatricalRegionProvider>
      </ProjectMetaProvider>
    </>
  )
}

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<MoviesPageProps> = async ({ preview = false, params }) =>
  getStaticPropsErrorHandler(
    { requestUrl: getUrlFromThisFilePath(params) },
    async (): Promise<GetStaticPropsResult<MoviesPageProps>> => {
      const locale = getLocaleFromParams(params)
      const project = (params?.projectSlug as string).toLowerCase()

      const client = getWebClient({ locale })
      let projectMeta = await getProjectAbout({ slug: project }, client, { locale, preview })

      const livestreams = await getProjectLivestreams(projectMeta, project, client)

      const shouldIncludePreview = process.env.NEXT_PUBLIC_ANGEL_ENV === 'development'
      const pageData = await getTheatricalPreReleasePageData({ slug: project }, client, { locale, preview })
      const catalogTitle = await fetchTitle<CatalogMovie>(locale, pageData.contentCatalogId)

      const theatricalRelease = await getTheatricalReleaseBySlug({
        theatricalSlug: project,
        opts: { preview: shouldIncludePreview, locale },
      })

      const canShowTickets = isSellingTickets(projectMeta?.primaryFlowPhases ?? [])

      const allTheatricalReleaseRegions = theatricalRelease?.regionCollection || []
      const supportedTheatricalRegions = theatricalRelease?.supportedTheatricalRegions || []
      const whereToWatch = await getWhereToWatch(project, preview)
      const contentfulProjectTheme = await getContentfulProjectTheme(project, preview)

      let franchise
      if (projectMeta.franchiseDetails?.slug) {
        franchise = await getLightFranchiseBySlug(projectMeta.franchiseDetails.slug)
      }

      // Projects may have a hub page and not be in Hydra so these values are undefined
      if (projectMeta.slug === undefined) {
        projectMeta = { ...projectMeta, slug: project }
      }

      if (projectMeta.public === undefined || (!projectMeta.public && catalogTitle)) {
        projectMeta = { ...projectMeta, public: true }
      }

      const redirect = getRedirect(project, projectMeta)
      if (redirect) return redirect

      return {
        props: {
          allTheatricalReleaseRegions,
          catalogTitle: omitUndefineds(catalogTitle) ?? undefined,
          inTheaters: inTheaters(projectMeta.primaryFlowPhases ?? []),
          isStreaming: isStreaming(projectMeta.primaryFlowPhases ?? []),
          livestreams,
          locale,
          pageData,
          supportedTheatricalRegions,
          whereToWatch,
          contentfulProjectTheme,
          canShowTickets,
          franchise: omitUndefineds(franchise) ?? null,
          projectMeta,
          ...redact(projectMeta, ['primaryFlowPhases']),
          ...(await loadTranslations(locale, [
            'common',
            'home',
            'watch',
            'app-promo',
            'ads',
            'account',
            'theatrical-presales',
            'pif',
            'guild',
          ])),
        },
        revalidate: catalogTitle ? Durations.FIFTEEN_MINUTES_IN_SECONDS : Durations.ONE_MINUTE_IN_SECONDS,
      }
    },
  )

export default MoviesPage

const getRedirect = (project: string, projectMeta: ProjectMeta): GetStaticPropsResult<MoviesPageProps> | null => {
  if (project === slugs.dryBar) {
    return {
      redirect: {
        destination: paths.shows.dryBar,
        permanent: false,
      },
    }
  }

  if (project === slugs.truthAndConviction) {
    return {
      redirect: {
        destination: paths.movies.truthAndTreason,
        permanent: false,
      },
    }
  }

  if (project === slugs.truthAndTreasonSeries) {
    return {
      redirect: {
        destination: paths.shows.truthAndTreasonSeries,
        permanent: false,
      },
    }
  }

  if (!projectMeta.hasHubPage) {
    return {
      redirect: {
        destination: `${paths.watch.index}/${project}`,
        permanent: false,
      },
    }
  }

  if (projectMeta?.projectType !== 'movie' && !isProjectThatsBothSeriesAndMovie(projectMeta)) {
    return {
      notFound: true,
      revalidate: Durations.FIFTEEN_MINUTES_IN_SECONDS,
    }
  }

  return null
}
